<template>
  <div class="personal-account">
    <div class="personal-account__container _container">
      <BreadCrumbs :items="[[$t('main'), '/'], [$t('account.account')]]" />
      <div class="personal-account__breadcrumbs"></div>
      <div class="personal-account__body">
        <h1 class="personal-account__title main-title">
          {{ $t("account.account") }}
        </h1>
        <div class="personal-account__content">
          <div class="personal-account__wrapper">
            <TabItem
              class="personal-account__tabs"
              :tabItems="tabs"
              :selected="selectedTab"
              @setTab="selectTab"
            ></TabItem>
            <button class="personal-account__btn main-btn" @click="logOut">
              {{ $t("buttons.exit") }}
            </button>
          </div>

          <TabBlock :isSelected="selectedTab === tabs[0].id">
            <PersonalInfo :user="user"></PersonalInfo>
          </TabBlock>
          <TabBlock :isSelected="selectedTab === tabs[1].id">
            <PersonalOrders :orders="orders"></PersonalOrders>
          </TabBlock>
          <TabBlock :isSelected="selectedTab === tabs[2].id">
            <PersonalReviews :reviews="user.reviews"></PersonalReviews>
          </TabBlock>
          <TabBlock :isSelected="selectedTab === tabs[3].id">
            <PersonalFavorites></PersonalFavorites>
          </TabBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/TabItem";
import TabBlock from "@/components/TabBlock";
import PersonalInfo from "@/components/personal-account/PersonalInfo";
import PersonalOrders from "@/components/personal-account/PersonalOrders";
import PersonalFavorites from "@/components/personal-account/PersonalFavorites";
import PersonalReviews from "@/components/personal-account/PersonalReviews";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";

export default {
  components: {
    PersonalReviews,
    PersonalFavorites,
    PersonalOrders,
    TabBlock,
    TabItem,
    PersonalInfo,
    BreadCrumbs,
  },
  data() {
    return {
      selectedTab: "",
      orders: [],
    };
  },
  created() {
    this.requestUser();
    this.fetchOrders();
    this.selectedTab = this.tabs[0].id;
  },
  computed: {
    ...mapState(["user"]),
    tabs() {
      if (this.user.type === "individual") {
        return [
          {
            id: 1,
            name: "account.myData",
          },
          {
            id: 2,
            name: "account.orders",
          },
          {
            id: 3,
            name: "account.reviews",
          },
          {
            id: 4,
            name: "account.favorites",
          },
          {
            id: 5,
            name: "account.bonus",
          },
        ];
      } else {
        return [
          {
            id: 1,
            name: "account.organization",
          },
          {
            id: 2,
            name: "account.orders",
          },
          {
            id: 3,
            name: "account.reviews",
          },
          {
            id: 4,
            name: "account.favorites",
          },
          {
            id: 5,
            name: "account.bonus",
          },
        ];
      }
    },
  },
  methods: {
    ...mapMutations(["SET_FAVORITES"]),
    ...mapActions(["checkAuth", "requestUser"]),
    selectTab(tab) {
      this.selectedTab = tab;
    },
    fetchOrders() {
      axios
        .get(`V1/orders`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          this.orders = response.data.data;
        })

        .catch((error) => {
        });
    },

    logOut() {
      axios
        .post(
          "V1/logout",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((response) => {
          this.$router.push("/");
          localStorage.removeItem("access_token");
          this.SET_FAVORITES([]);
          this.checkAuth();
          this.SET_USER(null);
        })
        .catch((errors) => {
        });
    },
  },
};
</script>


<style lang="scss">
.personal-account {
  padding: 50px 0 67px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 140px 0;
  }

  &__title {
    margin: 0 0 40px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 15px 0;
    }
  }

  &__wrapper {
    min-width: 250px;
    @media (max-width: $mobile + px) {
      margin: 0 0 50px 0;
    }
  }

  &__content {
    @media (min-width: $mobile + px) {
      display: flex;
      gap: 0 20px;
      align-items: flex-start;
    }
  }

  &__tabs {
    margin: 0 0 20px 0;
    .tab-list {
      display: block;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      border-radius: 10px;
      padding: 9px 0 17px 0;

      li {
        &:not(:last-child) {
          border-bottom: 1px solid #e9e9e9;
        }
      }
    }

    .tab-link {
      padding: 11px 25px 11px 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 117%;

      &.active {
        color: #d81921;
      }
    }

    @media (max-width: $mobile + px) {
      margin: 0 0 30px 0;
    }
  }

  .tab-content {
    width: 100%;
  }

  &__btn {
    width: 100%;
    @media (min-width: $mobile + px) {
      margin: 0 0 30px 0;
    }
  }
}
</style>