<template>
  <div class="personal-favorites">
    <h2 class="personal-favorites__title second-title">{{ $t('myFavorites') }}</h2>
    <div class="personal-favorites__body">
      <div class="personal-favorites__items">
        <div class="personal-favorites__item" v-for="(favorite, id) in favorites" :key="id">
          <!--          <ProductItem @change="fetchFavorites" :product="favorite" :favorite="true"></ProductItem>-->
          <ProductItem
            @change="update"
            @click="$router.push(`/product/${favorite.slug}`)"
            :isFavorites="true"
            :product="favorite"
          ></ProductItem>
        </div>
      </div>
      <router-link to="/" class="personal-favorites__btn main-btn">{{
        $t("continueShopping")
      }}</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductItem from "@/components/ProductItem";

export default {
  components: { ProductItem },
  data() {
    return {
      personalFavorites: [],
    };
  },
  computed: {
    ...mapState(["favorites"]),
  },
  methods: {
    ...mapActions(["fetchFavorites", "addFavorite"]),
    update() {
      this.fetchFavorites();
    },
  },
  created() {
    if (localStorage.getItem("favorites")) {
      const favoriteProducts = JSON.parse(localStorage.getItem("favorites"));
      favoriteProducts.forEach((product) => {
        this.addFavorite({ product_id: product.id });
      });
      window.localStorage.removeItem("favorites");
    }
    this.fetchFavorites();
  },
  beforeMount() {
  },
};
</script>

<style lang="scss">
.personal-favorites {
  &__title {
    margin: 0 0 20px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 30px 0;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 0 23px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 35px 0;
      gap: 10px;
    }
  }
  &__item {
    flex: 0 0 288px;
    @media (max-width: $pc + px) {
      flex: 0 1 calc(33% - 11px);
    }
    @media (max-width: $tablet + px) {
      flex: 0 1 calc(50% - 11px);
    }
    @media (max-width: $mobileSmall + px) {
      flex: 0 1 calc(50% - 5px);
    }
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }
}
</style>