<template>
  <div class="personal-account__reviews personal-reviews">
    <h2 class="personal-reviews__title second-title">{{ $t("reviews.my") }}</h2>
    <div class="personal-reviews__body">
      <div class="personal-reviews__items">
        <span v-if="reviews.length === 0">{{ $t("reviews.no") }}</span>
        <div
          class="personal-reviews__item"
          v-for="(review, id) in reviews"
          :key="id"
        >
          <ReviewComponent
            :review="review"
            :type="'personal'"
          ></ReviewComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewComponent from "@/components/ReviewComponent";
export default {
  components: { ReviewComponent },
  props: {
    reviews: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.personal-reviews {
  &__title {
    margin: 0 0 22px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 62px 0;
    }
  }
  &__item {
    &:not(:last-child) {
      margin: 0 0 68px 0;
    }
  }
}
</style>