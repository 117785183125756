<template>
  <div class="personal-info" v-if="info">
    <div class="personal-info__user">
      <h2 class="personal-info__title second-title" v-if="individualUser">
        {{ $t("account.myData") }}
      </h2>
      <h2 class="personal-info__title second-title" v-if="!individualUser">
        {{ $t("account.organization") }}
      </h2>
      <VeeForm @submit="changeInfo" ref="userInfo" class="personal-info__form">
        <div class="personal-info__items">
          <div class="personal-info__item">
            <VeeField
              name="name"
              rules="required"
              type="text"
              :placeholder="
                individualUser
                  ? $t('placeholder.name')
                  : $t('placeholder.organization')
              "
              class="personal-info__input main-input"
              v-model="info.name"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="name"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="personal-info__item" v-if="individualUser">
            <VeeField
              name="surname"
              rules="required"
              type="text"
              :placeholder="$t('placeholder.surname')"
              class="personal-info__input main-input"
              v-model="info.sname"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="surname"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="personal-info__item" v-if="!individualUser">
            <VeeField
              rules="required|numeric"
              name="bin"
              type="text"
              :placeholder="$t('placeholder.bin')"
              class="personal-info__input main-input"
              v-model="info.bin"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="bin"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="personal-info__item" v-if="!individualUser">
            <VeeField
              rules="required"
              name="entityAddress"
              type="text"
              :placeholder="$t('placeholder.yur')"
              class="personal-info__input main-input"
              v-model="info.entity_address"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="entityAddress"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="personal-info__item" v-if="!individualUser">
            <VeeField
              rules="required"
              name="actualAddress"
              type="text"
              :placeholder="$t('placeholder.fact')"
              class="personal-info__input main-input"
              v-model="info.actual_address"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="actualAddress"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="personal-info__item">
            <VeeField
              rules="required|email"
              name="email"
              type="email"
              placeholder="E-mail"
              class="personal-info__input main-input"
              v-model="info.email"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="email"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="personal-info__item">
            <VeeField
              rules="required"
              name="phone"
              type="tel"
              class="personal-info__input main-input"
              placeholder="+7"
              v-model="info.phone_number"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="phone"
              ></VeeErrorMessage>
            </transition>
          </div>
        </div>
        <div class="personal-info__btns">
          <button
            v-if="saveValue"
            type="submit"
            class="personal-info__btn main-btn"
            style="background: #d81921; color: white"
          >
            {{ $t("buttons.save") }}
          </button>
          <button
            @click="accessInputs"
            v-else
            style="background: #ffffff"
            class="personal-info__btn main-btn"
          >
            {{ $t("buttons.edit") }}
          </button>
        </div>
      </VeeForm>
    </div>

    <div class="personal-info__password" @click="isOpenModal = true">
      {{ $t("buttons.changePassword") }}
    </div>
    <div class="personal-info__delivery">
      <h3 class="personal-info__small-title">
        {{ $t("labels.deliveryAddress") }}
      </h3>
      <div class="personal-info__addresses" v-if="user.addresses">
        <div
          class="personal-info__columns"
          v-for="(address, id) in user.addresses"
          :key="id"
        >
          <div class="personal-info__column">
            <div class="personal-info__address">
              {{ formatAddress(address) }}
            </div>
            <div class="personal-info__actions">
              <button
                class="personal-info__action"
                @click="editAddress(address)"
              >
                <span class="personal-info__icon"
                  ><img src="../../assets/icons/personal/edit.svg" alt=""
                /></span>
                <span class="personal-info__text">{{
                  $t("buttons.edit")
                }}</span>
              </button>
              <button
                class="personal-info__action"
                @click="deleteAddress(address)"
              >
                <span class="personal-info__icon"
                  ><img src="../../assets/icons/personal/delete.svg" alt=""
                /></span>
                <span class="personal-info__text">Удалить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <VeeForm
        @submit="addNewAddress"
        ref="addressForm"
        class="personal-info__form"
      >
        <div class="personal-info__items" v-if="isOpenForm">
          <div class="personal-info__item">
            <SelectComponent
              class="personal-info__select"
              @select="selectRegion"
              :options="regions"
              :placeholder="$t('placeholder.region')"
              :selected-option="selectedRegion"
            />
          </div>
          <div class="personal-info__item">
            <SelectComponent
              v-if="address.region_id"
              class="personal-info__select"
              :clear="clearAddress"
              @select="selectCity"
              :options="cities"
              :placeholder="$t('placeholder.city')"
              :selected-option="selectedCity"
            />
          </div>
          <div class="personal-info__item personal-info__item">
            <VeeField
              name="street"
              rules="required"
              type="text"
              :placeholder="$t('placeholder.street')"
              v-model="address.address"
              class="personal-info__input main-input"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="street"
              ></VeeErrorMessage>
            </transition>
          </div>
            <!-- v-if="individualUser" -->
          <div
            class="personal-info__item personal-info__item_address"
          >
            <VeeField
              name="apartment"
              type="number"
              :placeholder="$t('placeholder.kv')"
              v-model="address.apartment"
              class="personal-info__input main-input"
            />
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="apartment"
              ></VeeErrorMessage>
            </transition>
          </div>
        </div>
        <div class="personal-info__btns">
          <button
            class="personal-info__btn main-btn"
            type="submit"
            v-if="isOpenForm"
          >
            {{ $t("buttons.saveAddress") }}
          </button>
          <button
            class="personal-info__btn main-btn"
            @click="
              isOpenForm = !isOpenForm;
              clearInputs();
            "
          >
            <span v-if="!isOpenForm"> {{ $t("buttons.addAddress") }}</span>
            <span v-else> {{ $t("buttons.cancel") }}</span>
          </button>
        </div>
      </VeeForm>
    </div>

    <ModalComponent :is-open="isOpenModal" @close="isOpenModal = false">
      <ChangePassword @success="isOpenModal = false" />
    </ModalComponent>
  </div>
</template>

<script>
import SelectComponent from "@/components/SelectComponent";
import { mapActions, mapMutations, mapState } from "vuex";
import ModalComponent from "@/components/ModalComponent";
import ChangePassword from "@/components/modals/ChangePassword";
import axios from "axios";

export default {
  components: { ChangePassword, ModalComponent, SelectComponent },

  data() {
    return {
      text: "",
      selectedRegion: null,
      selectedCity: null,
      isOpenModal: false,
      clearAddress: false,
      isOpenForm: false,
      saveValue: false,
      info: {},
      address: {
        address: "",
        apartment: "",
        region_id: null,
        city_id: null,
      },
    };
  },
  props: {
    user: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  methods: {
    ...mapMutations(["SET_CITIES"]),
    ...mapActions(["fetchCities", "fetchRegions", "requestUser"]),
    selectRegion(option) {
      this.address.region_id = option.id;
      this.fetchCities(this.address.region_id);
      this.clearAddress = true;
    },
    selectCity(option) {
      this.address.city_id = option.id;
      this.clearAddress = false;
    },
    changeInfo() {
      axios
        .post(
          "/V1/user-update",
          {
            name: this.info.name,
            sname: this.info.sname,
            bin: this.info.bin,
            email: this.info.email,
            phone: this.info.phone_number,
            addresses: this.info.addresses,
            entity_address: this.info.entity_address,
            actual_address: this.info.actual_address,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((response) => {
          this.saveValue = false;
          let inputs = document.querySelectorAll(".personal-info__input");
          for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].disabled === false) {
              inputs[i].disabled = true;
            } else inputs[i].disabled = false;
          }
        })
        .catch((errors) => {
        });
    },
    addNewAddress() {
      if (this.address.id) {
        axios
          .put(
            "/V1/edit-address",
            {
              user_address_id: this.address.id,
              region_id: this.address.region_id,
              city_id: this.address.city_id,
              address: this.address.address,
              apartment: this.address.apartment,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
          .then((response) => {
            this.isOpenForm = false;
            this.clearInputs();
            this.requestUser()
          })
          .catch((errors) => {
          });
      } else {
        axios
          .post("/V1/add-address", this.address, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((response) => {
            this.isOpenForm = false;
            this.clearInputs();
            this.requestUser()
          })
          .catch((errors) => {
          });
      }
      this.requestUser();
    },
    editAddress(address) {
      this.address = JSON.parse(JSON.stringify(address));
      this.selectedRegion = {
        id: address.region_id,
        title: address.region_name,
      };
      this.selectedCity = {
        id: address.city_id,
        title: address.city_name,
      };
      this.isOpenForm = true;
    },
    clearInputs() {
      this.selectedCity = null;
      this.selectedRegion = null;
      this.address = {
        address: "",
        apartment: "",
        region_id: null,
        city_id: null,
      };
    },
    deleteAddress(address) {
      axios
        .delete("/V1/delete-address", {
          params: {
            user_address_id: address.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          this.requestUser();
          this.isOpenForm = false;
          this.clearInputs();
        })
        .catch((errors) => {
        });
    },
    accessInputs() {
      const user = this.$refs.userInfo;
      this.saveValue = true;
      let inputs = document.querySelectorAll(".personal-info__input");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].disabled === false) {
          inputs[i].disabled = true;
        } else inputs[i].disabled = false;
      }
    },
    formatAddress(address) {
      return (
        address.region_name +
        ", " +
        address.city_name +
        ", " +
        address.address +
        ", " +
        address.apartment
      );
    },
  },
  created() {
    this.fetchRegions();
    this.info = JSON.parse(JSON.stringify(this.user));
  },
  mounted() {
    let inputs = document.querySelectorAll(".personal-info__input");
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].disabled === false) {
        inputs[i].disabled = true;
      } else inputs[i].disabled = false;
    }
  },
  computed: {
    ...mapState(["regions", "cities"]),
    individualUser() {
      return this.user.type === "individual";
    },
  },
  watch: {
    auth: function () {
      if (this.auth) this.$route.push("/");
    },
    user: function () {
      this.info = JSON.parse(JSON.stringify(this.user));
    },
  },
};
</script>

<style lang="scss">
.personal-info {
  @media (min-width: $mobile + px) {
    max-width: 690px;
  }

  &__user {
    margin: 0 0 62px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 30px 0;
    }
  }

  &__password {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline;
    margin: 0 0 47px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 43px 0;
    }
  }

  &__title {
    margin: 0 0 18px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 30px 0;
    }
  }

  &__small-title {
    @extend .base-text-500;
    margin: 0 0 23px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 30px 0;
    }
  }

  &__items {
    @media (min-width: $mobileSmall + px) {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  &__item {
    flex: 0 1 calc(50% - 10px);

    &:not(:last-child) {
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 20px 0;
      }
    }
  }

  &__addresses {
    margin: 0 0 34px 0;
  }

  &__btns {
    margin: 32px 0 0 0;
    @media (min-width: $mobileSmall + px) {
      margin: 35px 0 0 0;
      display: flex;
      gap: 0 25px;
    }
  }

  &__btn {
    padding: 0 44px;

    &:last-child {
      padding: 0 33px;
      color: #d81921;
      border: 1px solid #d81921;
      background: #ffffff;
    }

    &:not(:last-child) {
      margin: 0 0 20px 0;
    }

    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }

  .select {
    width: 100%;
  }

  &__columns {
    &:not(:last-child) {
      margin: 0 0 15px 0;
      @media (max-width: $tablet + px) {
        margin: 0 0 25px 0;
      }
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 30px 0;
      }
    }
  }

  &__column {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: center;
      gap: 0 30px;
    }
  }

  &__address {
    font-weight: 500;
    flex: 0 1 400px;
    @media (max-width: $tablet + px) {
      margin: 0 0 10px 0;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__actions {
    display: flex;
    gap: 15px;
  }

  &__action {
    display: inline-flex;
    align-items: center;
  }

  &__text {
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 5px 0 0;
    @media (max-width: $mobileSmall + px) {
      width: 14px;
      height: 14px;
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
      }
    }
  }
}
</style>